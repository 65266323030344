import $ from 'jquery';

;(() => {

  const $investments = $('#investments');

  if (!$investments.length) {
    return;
  }

  let categoryFilter = [];

  const setupPortfolio = () => {
    const $investmentsPortfolioImagesWrapper = $investments.find('.investments-portfolio__images-wrapper');
    const $investmentsPortfolioFilters = $investments.find('.investments-portfolio__filters');
    const $investmentsPortfolioFiltersButton = $investmentsPortfolioFilters.find('button');

    if ($investmentsPortfolioFilters.length && $investmentsPortfolioFiltersButton) {
      const onClickInvestmentsPortfolioFiltersButton = (event) => {
        const $target = $(event.target);
        const category = $target.attr('category');
        const $allContent = $investments.find('.investments-portfolio__content-wrapper');

        $investmentsPortfolioImagesWrapper.removeClass('disabled current');
        $allContent.removeClass('show');
        $investmentsPortfolioFiltersButton.removeClass('active');

        if (category === 'all-properties') {
          categoryFilter = [];
          $target.addClass('active');          
        } else if (categoryFilter.includes(category)) {
          categoryFilter = [];
          $target.removeClass('active');
        } else {
          categoryFilter = [category];
          $target.addClass('active');
        }

        $investmentsPortfolioImagesWrapper.addClass('hide');
        $investments.find('.investments-portfolio__content-wrapper').addClass('hide');

        if (categoryFilter.length) {
          $investmentsPortfolioImagesWrapper.each((index) => {
            const $current = $investmentsPortfolioImagesWrapper.eq(index);
            const indexAttr = $current.attr('index');
            const $currentContent = $investments.find(`.investments-portfolio__content-wrapper[index=${indexAttr}]`);
  
            if (categoryFilter.includes($current.attr('category'))) {
              $current.removeClass('hide');
              $currentContent.removeClass('hide');
            }
          });
        } else {
          $investmentsPortfolioImagesWrapper.removeClass('hide');
          $investments.find('.investments-portfolio__content-wrapper').removeClass('hide');
        }

        let small = 0;
        let large = 0;
        let orderIndex = 0;

        $investmentsPortfolioImagesWrapper.css('order', '999999');
        $investmentsPortfolioImagesWrapper.each((index) => {
          const $current = $investmentsPortfolioImagesWrapper.eq(index);

          if (!$current.hasClass('hide')) {
            small = orderIndex % 1 === 0 ? small + 1 : small;
            large = orderIndex % 2 === 0 ? large + 2 : large;
  
            $current.css('order', orderIndex);
            $current.attr('order-small', small);
            $current.attr('order-large', large);

            orderIndex += 1;
          }
        });
      };

      $investmentsPortfolioFiltersButton.click(onClickInvestmentsPortfolioFiltersButton);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const typeParam = urlParams.get('type');
    const $investmentsPortfolioFiltersParam = $investments.find(`.investments-portfolio__filters [category=${typeParam}]`);

    if ($investmentsPortfolioFiltersParam.length) {
      $investmentsPortfolioFiltersParam.click();
    }

    const $searchPortfolio = $investments.find('#search-portfolio');
    const $inputSearch = $searchPortfolio.find('.input-search');

    if ($searchPortfolio.length) {
      $searchPortfolio.submit((event) => {
        event.preventDefault();

        if ($inputSearch.length) {
          let value = $inputSearch.val();

          $investmentsPortfolioImagesWrapper.addClass('unfiltered');

          if (value.length) {
            value.toLocaleLowerCase();
            $investmentsPortfolioImagesWrapper.each((index) => {
              const $current = $investmentsPortfolioImagesWrapper.eq(index);
              const name = $current.find('p').text().toLocaleLowerCase()

              if (name.includes(value)) {
                $current.removeClass('unfiltered');
              }
            });
          } else {
            $investmentsPortfolioImagesWrapper.removeClass('unfiltered');
          }
        }
      });
    }

    $investmentsPortfolioImagesWrapper.click((event) => {
      const $current = $(event.target);
      const $allContent = $investments.find('.investments-portfolio__content-wrapper');

      if ($current.hasClass('current')) {
        $investmentsPortfolioImagesWrapper.removeClass('disabled current');
        $allContent.removeClass('show');
        $allContent.css('order', '');
      } else {
        const index = $current.attr('index');
        const small = $current.attr('order-small');
        const large = $current.attr('order-large');
  
        const $allContent = $investments.find('.investments-portfolio__content-wrapper');
        const $content = $investments.find(`.investments-portfolio__content-wrapper[index=${index}]`);
        const order = $(window).width() >= 1024 ? large : small;
  
        $investmentsPortfolioImagesWrapper.addClass('disabled');
        $investmentsPortfolioImagesWrapper.removeClass('no-hover');
        $current.removeClass('disabled').addClass('current');
        $allContent.removeClass('show');
        $content.addClass('show');
        $content.css('order', order);
      }
    });

    $investmentsPortfolioImagesWrapper.hover((event) => {
      if ($(window).width() < 1024) {
        $investmentsPortfolioImagesWrapper.removeClass('no-hover');
      } else {
        const $current = $(event.target);

        $investmentsPortfolioImagesWrapper.addClass('no-hover');
        $current.removeClass('no-hover');
      }
    });

    $investmentsPortfolioImagesWrapper.mouseleave((event) => {
      $investmentsPortfolioImagesWrapper.removeClass('no-hover');
    });

    

    const $singleContentClose = $investments.find('.single-content-close');

    const onClickSingleContentClose = () => {
      const $allContent = $investments.find('.investments-portfolio__content-wrapper');

      $investmentsPortfolioImagesWrapper.removeClass('disabled current');
      $allContent.removeClass('show');
    };

    $singleContentClose.click(onClickSingleContentClose);
  };

  $(document).ready(() => {
    setupPortfolio();
  });

  $(window).resize(() => {
    const $allContent = $investments.find('.investments-portfolio__content-wrapper');

    $investmentsPortfolioImagesWrapper.removeClass('disabled current');
    $allContent.removeClass('show');
  });

})();