;(function(){
  const openAccordeonButton = document.querySelectorAll('#js--open-accordeon')
  const accordeonContent = document.querySelectorAll('#js--accordeon-content')
  if (!openAccordeonButton.length) { return }

  openAccordeonButton.forEach((button, i) => {
    button.addEventListener('click', () => {
      accordeonContent[i].classList.toggle('hidden')
    })
  })
})();