import $ from 'jquery';

import './scss/style.scss';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

import './js/script';

const viewportWidth = $(window).width();
let slidesNews = 2
let slidesLogos = 4
if (viewportWidth > 400 ){
  slidesNews = 2;
  slidesLogos = 4
} else {
  slidesNews = 1;
  slidesLogos = 1
}

$('#news-list .item-content-gallery').slick({
  infinite: true,
  centerMode: true,
  prevArrow: $('.prev'),
  nextArrow: $('.next'),
  slidesToScroll: 1,
  slidesToShow: slidesNews,
})

$('.logosCarousel__wrapper').slick({
  infinite: true,
  centerMode: true,
  prevArrow: $('.prev-logo'),
  nextArrow: $('.next-logo'),
  slidesToScroll: 1,
  slidesToShow: slidesLogos,
  variableWidth: false,
})

const $heroCarouselContainer = $('.hero-carousel-container');

if ($heroCarouselContainer.length) {
  let carouselSpeed = 10;

  if ($heroCarouselContainer.attr('carousel-speed').length) {
    carouselSpeed = parseInt($heroCarouselContainer.attr('carousel-speed'), 10);
  }

  $heroCarouselContainer.slick({
    infinite: true,
    prevArrow: $('.hero-carousel-last'),
    nextArrow: $('.hero-carousel-next'),
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: carouselSpeed * 1000,
  });
  
}
