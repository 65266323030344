import $ from 'jquery';

;(() => {

  const $home = $('#home');

  if (!$home.length) {
    return;
  }

  const setupOurPlatformAccordion = () => {
    const $ourPlatformAccordion = $home.find('.our-platform-accordion');
    const $accordionItem = $ourPlatformAccordion.find('.accordion-item');
    const $accordionItemShow = $accordionItem.find('.accordion-item-show');

    if (
      $ourPlatformAccordion.length &&
      $accordionItem.length &&
      $accordionItemShow.length
    ) {
      const onClickAccordionItemShow = (event) => {
        const $target = $(event.target);
        const $parent = $target.parent();

        if ($parent.hasClass('show')) {
          $parent.removeClass('show');
        } else {
          $parent.addClass('show');
        }
      };

      $accordionItemShow.click(onClickAccordionItemShow);
    }
  };

  const $imageCarousel = $home.find('.image-carousel');
  const $imageCarouselContent = $imageCarousel.find('.image-carousel-content');
  const $imageCarouselNavLast = $imageCarouselContent.find('.image-carousel-nav-last');
  const $imageCarouselNavNext = $imageCarouselContent.find('.image-carousel-nav-next');
  const $imageCarouselItem = $imageCarouselContent.find('.image-carousel-item');

  let imageCarouselInterval = null;
  let imageCarouselPosition = 0;

  const updateImageCarousel = (position) => {
    imageCarouselPosition = position;

    if (position < 0) {
      imageCarouselPosition = $imageCarouselItem.length - 1;
    }

    if (position > $imageCarouselItem.length - 1) {
      imageCarouselPosition = 0;
    }

    $imageCarouselItem.removeClass('active');
    $imageCarouselItem.eq(imageCarouselPosition).addClass('active');
  };

  const setImageCarouselInterval = () => {
    imageCarouselInterval = setInterval(() => {
      imageCarouselPosition += 1;
      updateImageCarousel(imageCarouselPosition);
    }, 3000);
  };

  const resetImageCarouselInterval = () => {
    clearInterval(imageCarouselInterval);
    setImageCarouselInterval();
  };

  const setupImageCarousel = () => {
    if (
      !$imageCarousel.length ||
      !$imageCarouselContent.length ||
      !$imageCarouselItem.length
    ) {
      return;
    }

    setImageCarouselInterval();

    $imageCarouselNavLast.click(() => {
      imageCarouselPosition -= 1;

      resetImageCarouselInterval();
      updateImageCarousel(imageCarouselPosition);
    });

    $imageCarouselNavNext.click(() => {
      imageCarouselPosition += 1;

      resetImageCarouselInterval();
      updateImageCarousel(imageCarouselPosition);
    });
  };

  $('document').ready(() => {
    setupOurPlatformAccordion();
    setupImageCarousel();
  });

})();