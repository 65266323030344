import $ from 'jquery';

;(() => {

  const $contactUs = $('#contact-us');
  const $contactUsModal = $('#contact-us-modal');

  if (!$contactUs.length || !$contactUsModal.length) {
    return;
  }

  const setupContactUsModal = () => {
    const $contactUsShowForm = $contactUs.find('.contact-us-show-form');
    const $contactUsHideForm = $contactUsModal.find('.contact-us-hide-form');

    if ($contactUsShowForm.length) {
      const onClickContactUsShowForm = (event) => {
        $contactUsModal.addClass('show');
      };

      $contactUsShowForm.click(onClickContactUsShowForm);
    }

    if ($contactUsHideForm.length) {
      const onClickContactUsHideForm = (event) => {
        $contactUsModal.removeClass('show');
      };

      $contactUsHideForm.click(onClickContactUsHideForm);
    }
  };

  $('document').ready(() => {
    setupContactUsModal();
  });

})();
