;(function() {

  const footerItems = document.querySelectorAll('.footer-nav-list__item')

  if (!footerItems) { return }

  footerItems.forEach((item, itemIndex) => {
    item.addEventListener('mouseover', () => {
      footerItems.forEach((inactiveItem, inactiveItemIndex) => {
        if (inactiveItemIndex !== itemIndex) {
          inactiveItem.classList.add('inactive-footer')
        }
      })
    })

    item.addEventListener('mouseout', () => {
      footerItems.forEach((inactiveItem, inactiveItemIndex) => {
        if (inactiveItemIndex !== itemIndex) {
          inactiveItem.classList.remove('inactive-footer')
        }
      })
    })
  })

})();
