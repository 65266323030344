import $ from 'jquery';

;(() => {

  const $newsList = $('#news-list');

  if (!$newsList.length) {
    return;
  }
  
  const $newsListGallery = $newsList.find('.news-list-gallery');
  const $newsListItem = $newsListGallery.find('.news-list-item');
  const perPage = 10;
  let page = 0;

  const setupNewsListGallery = () => {
    if ($newsListGallery.length && $newsListItem.length) {
      const $newsListItemShow = $newsListItem.find('.news-list-item-show');

      const onClickNewsListItemShow = (event) => {
        const $target = $(event.target);
        const $parent = $target.parent();

        if ($parent.hasClass('show')) {
          $parent.removeClass('show');
        } else {
          $parent.addClass('show');
        }
      };

      $newsListItemShow.click(onClickNewsListItemShow);

      const updatePagination = () => {
        $newsListItem.addClass('hide')
        $newsListItem.each((index) => {
          if (index >= page * perPage && index < perPage * (page + 1)) {
            $newsListItem.not('.unfiltered').eq(index).removeClass('hide');
          }
        });
      };

      updatePagination();

      const $paginationItemButton = $newsList.find('.news-list-pagination .pagination-item-button');

      if ($paginationItemButton.length) {
        const onClickPaginationItemButton = (event) => {
          const $target = $(event.target);

          if ($target.hasClass('active')) {
            return;
          }

          $paginationItemButton.removeClass('active');
          $target.addClass('active');
          page = parseInt($target.attr('page'), 10) - 1;

          updatePagination();
          window.scrollTo(0, 0);
        };

        $paginationItemButton.click(onClickPaginationItemButton);
      }

      const $newsSearchForm = $newsList.find('.news-search-form');

      if ($newsSearchForm.length) {
        $newsSearchForm.submit((event) => {
          event.preventDefault();
          
          const $newsSearchFormInput = $newsSearchForm.find('.news-search-form-input');

          if ($newsSearchFormInput.length) {
            let value = $newsSearchFormInput.val();
            
            $newsListItem.addClass('unfiltered');
            
            if (value.length) {
              value = value.toLocaleLowerCase();

              $newsListItem.each((index) => {
                const $current = $newsListItem.eq(index);
                let text = ''
                let name = ''

                if ($current.find('.item-content-text').length) {
                  text = $current.find('.item-content-text').html().toLocaleLowerCase();
                }

                if ($current.find('.news-list-item-show').length) {
                  name = $current.find('.news-list-item-show').text().toLocaleLowerCase();
                }

                if (name.includes(value) || text.includes(value)) {
                  $current.removeClass('unfiltered');
                }
              });
            } else {
              $newsListItem.removeClass('unfiltered');
            }

            const currentAmount = $newsListItem.not('.unfiltered').length;

            if (currentAmount <= perPage) {
              $paginationItemButton.addClass('hide');
            } else {
              for (let i = 1; i <= Math.ceil(currentAmount / perPage); i += 1) {
                $paginationItemButton.eq(i - 1).removeClass('hide');
              }
            }

            $paginationItemButton.removeClass('active');
            $paginationItemButton.eq(0).addClass('active');
            page = 0;
            updatePagination();
            window.scrollTo(0, 0);
          }
        });
      }
    }
  };

  $('document').ready(() => {
    setupNewsListGallery();
  });

})();
