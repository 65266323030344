;(function(){

  const openHeaderLink = document.querySelectorAll('#js--open-full')
  const closedMenu = document.querySelectorAll('#js--closed-menu')

  if (!openHeaderLink.length) { return }
  
  openHeaderLink.forEach(headerLink => {
    if (headerLink) {
      headerLink.addEventListener('click', () => {
        closedMenu.forEach(closedItems => closedItems.classList.toggle('hidden'))
      })
    }
  })
})();

;(function(){
  const mobileMenuButton = document.getElementById('js--open-menu-mobile')
  const mobileMenu = document.getElementById('js--menu-mobile')

  if (!mobileMenuButton) { return }
  
  mobileMenuButton.addEventListener('click', () => {
    mobileMenu.classList.toggle('hidden')
  })

})();

;(function(){
  const mainMenuItems = document.querySelectorAll('.main-menu')

  if (!mainMenuItems) { return }

  mainMenuItems.forEach((menuItem, menuItemIndex) => {
    menuItem.addEventListener('mouseover', () => {
      mainMenuItems.forEach((inactiveMenu, inactiveMenuIndex) => {
        if (inactiveMenuIndex !== menuItemIndex) {
          inactiveMenu.classList.add('inactive')
        }
      })
    })

    menuItem.addEventListener('mouseout', () => {
      mainMenuItems.forEach((inactiveMenu, inactiveMenuIndex) => {
        if (inactiveMenuIndex !== menuItemIndex) {
          inactiveMenu.classList.remove('inactive')
        }
      })
    })
  })

})();
