import $ from 'jquery';

;(() => {

  const $navigation = $('#navigation');
  
  if (!$navigation.length) {
    return;
  }
  
  const $header = $('#header');
  const $showNavigation = $('#show-navigation');
  const $showCollapsibleList = $navigation.find('.show-collapsible-list');

  const showNavigation = () => {
    $navigation.addClass('show');
    $header.addClass('show');
    $showNavigation.addClass('show');
    $('body').css({overflow: 'hidden', height: '100vh'});
  };

  const hideNavigation = () => {
    $navigation.removeClass('show');
    $header.removeClass('show');
    $showNavigation.removeClass('show');
    $('body').css({overflow: 'initial', height: 'initial'});
  };

  const onClickShowNavigation = () => {
    if ($showNavigation.hasClass('show')) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };

  const onClickShowCollapsibleList = () => {
    if ($navigation.hasClass('show')) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };

  $('document').ready(() => {
    $showNavigation.click(onClickShowNavigation);

    if ($showCollapsibleList.length) {
      $showCollapsibleList.click(onClickShowCollapsibleList);
    }
  });
})();
