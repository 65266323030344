import $ from 'jquery';

;(() => {

  const $teamsCapital = $('#teams-capital');

  if (!$teamsCapital.length) {
    return;
  }

  const $teamsListAccordeonImage = $teamsCapital.find('.teams-list-accordeon-image');
  const $itemImageElement = $teamsListAccordeonImage.find('.item-image-element');
  const $imageGalleryItemInformation = $teamsListAccordeonImage.find('.image-gallery-item-information');
  const $imageGalleryItemInformationName = $teamsListAccordeonImage.find('.image-gallery-item-information-name');
  const $itemInformationHide = $teamsListAccordeonImage.find('.item-information-hide');
  const $accordeonImageShow = $teamsListAccordeonImage.find('.accordeon-image-show');

  const setupTeamsListAccordeonImage = () => {
    if (
      $teamsListAccordeonImage.length &&
      $itemImageElement.length &&
      $imageGalleryItemInformation.length
    ) {
      const onClickItemImageElement = (event) => {
        const $target = $(event.target);
        const $parent = $target.parent();
        const index = $parent.attr('index');
        const $information = $teamsListAccordeonImage.find(`.image-gallery-item-information[index=${index}]`);
        const order = $(window).width() >= 1024 ? $parent.attr('order-large') : $parent.attr('order-small');

        $itemImageElement.addClass('disabled');
        $imageGalleryItemInformation.removeClass('active');
        $target.removeClass('disabled');
        $information.addClass('active');
        $information.css('order', order);
      };

      const onClickItemInformationHide = (event) => {
        $itemImageElement.removeClass('disabled');
        $imageGalleryItemInformation.removeClass('active');
      };

      $itemImageElement.click(onClickItemImageElement);
      $imageGalleryItemInformationName.click(onClickItemInformationHide);
      $itemInformationHide.click(onClickItemInformationHide);
    }

    if ($accordeonImageShow.length) {
      const onClickAccordeonImageShow = (event) => {
        const $target = $(event.target);
        const $content = $target.parent().find('.teams-list-accordeon-image-gallery');

        if ($content.length) {
          if ($target.hasClass('show')) {
            $target.removeClass('show');
            $content.removeClass('show');
          } else {
            $target.addClass('show');
            $content.addClass('show');
          }
        }
      };

      $accordeonImageShow.click(onClickAccordeonImageShow);
    }
  };

  const $teamsListAccordeonText = $teamsCapital.find('.teams-list-accordeon-text');
  const $textGalleryItem = $teamsListAccordeonText.find('.text-gallery-item');
  const $itemTextName = $textGalleryItem.find('.item-text-name');
  const $accordeonTextShow = $teamsListAccordeonText.find('.accordeon-text-show');

  const setupTeamsListAccordeonText = () => {
    if (
      $teamsListAccordeonText.length &&
      $textGalleryItem.length &&
      $itemTextName.length
    ) {
      const onClickItemTextName = (event) => {
        const $target = $(event.target);
        const $parent = $target.parent();
        const $content = $parent.find('.item-text-information');
        const $hide = $parent.find('.item-text-information-hide');

        $textGalleryItem.addClass('disabled');
        $textGalleryItem.find('.item-text-information').removeClass('show');
        $textGalleryItem.find('.item-text-information-hide').removeClass('show');
        
        $parent.removeClass('disabled');
        $content.addClass('show');
        $hide.addClass('show');
      };

      $itemTextName.click(onClickItemTextName);
    }

    if ($accordeonTextShow.length) {
      const onClickAccordeonTextShow = (event) => {
        const $target = $(event.target);
        const $content = $target.parent().find('.teams-list-accordeon-text-gallery');

        if ($content.length) {
          if ($target.hasClass('show')) {
            $target.removeClass('show');
            $content.removeClass('show');
          } else {
            $target.addClass('show');
            $content.addClass('show');
          }
        }
      };

      $accordeonTextShow.click(onClickAccordeonTextShow);
    }
  };

  $(document).ready(() => {
    setupTeamsListAccordeonImage();
    setupTeamsListAccordeonText();
  });

  $(window).resize(() => {
    $imageGalleryItemInformation.removeClass('active');
    $imageGalleryItemInformation.css('order', '');
  });

})();
